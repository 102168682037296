.breadcrumbs-container {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
    letter-spacing: -0.4px;
    padding: 4px 4px 4px 0;
    border-radius: 4px;
    width: 100%;
}

.breadcrumbs>* {
    display: inline-block;
    margin-right: 10px;
    color: #415385;
    font-size: 13px;
}

.breadcrumbs .crumb:after {
    content: ">";
    margin-left: 10px;
}

.breadcrumbs .crumb:last-child:after {
    display: none;
}

.crumb {
    margin-right: 8px;
}

.crumb a {
    text-decoration: none;
    color: #415385;
    /* Link color */
}

.crumb a:hover {
    text-decoration: underline;
}

.crumb:last-child {
    color: #6c757d;
    /* Current page color */
    pointer-events: none;
}